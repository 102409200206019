<template>

<div class="textimage" :class="{'is-left': block.align === 1, 'is-right': block.align === 3, 'is-grey': block.grey}">

	<com-inner class="textimage-inner">

		<com-text class="textimage-text" :block="block">

			<template v-if="block.button">

				<a :href="block.button.url" :class="{'is-more': block.button.type === 2, 'is-left': block.button.align === $constants.buttonAlign.left, 'is-center': block.button.align === $constants.buttonAlign.center, 'is-right': block.button.align === $constants.buttonAlign.right}" class="textimage-button"><app-icon s="chevronRight" v-if="block.button.type === 2" />{{ block.button.text }}</a>

			</template>

		</com-text>

		<div class="textimage-image" :class="{'is-rounded': block.rounded, 'is-bordered': block.border}" v-bgimage="block.image" />

	</com-inner>

</div>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock],

	components: {

		'com-text': () => import('./Text')

	}

}

</script>

<style scoped>

.textimage {
	margin-bottom: 20px;
}

.textimage.is-grey {
	background-color: #f5f5f5;
	padding-bottom: 20px;
	padding-top: 40px;
	margin-bottom: 40px;
}

.textimage-inner {
	display: flex;
	align-items: center;
	padding: 0px!important;
	max-width: 1120px!important;
}

.is-tablet .textimage-inner {
	padding: 0px 20px;
}

.is-mobile .textimage-inner {
	padding: 0px 10px;
	flex-direction: column!important;
}

.textimage.is-left .textimage-inner {
	flex-direction: row-reverse;
}

.textimage-text {
	flex-grow: 1;
	padding-right: 40px;
}

.is-mobile .textimage-text {
	padding-right: 0px;
}

.textimage.is-left .textimage-text {
	padding-left: 40px;
}

.is-mobile .textimage.is-left .textimage-text {
	padding-left: 0px;
}

.textimage-image {
	flex-shrink: 0;
	width: 300px;
	height: 300px;
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.is-tablet .textimage-image {
	width: 180px;
	height: 180px;
}

.is-mobile .textimage-image {
	width: 180px;
	height: 180px;
}

.textimage-image.is-rounded {
	border-radius: 50%;
	background-position: 50% 50%;
	background-size: cover;
}

.textimage-image.is-bordered {
	border: 14px solid #b7b7b7;
}

.is-tablet .textimage-image.is-bordered {
	border-width: 10px;
}

.is-mobile .textimage-image.is-bordered {
	border-width: 6px;
}

.textimage-button {
	height: 44px;
	background-color: #1a234c;
	color: #fff!important;
	font-size: 12px;
	letter-spacing: 1.2px;
	text-transform: uppercase;
	display: block;
	border-radius: 22px;
	line-height: 44px;
	margin-top: 20px;
	width: 160px;
	text-align: center;
}

.textimage-button.is-left {
	margin: 20px auto 0px 0px;
}

.textimage-button.is-center {
	margin: 20px auto 0px auto;
}

.textimage-button.is-right {
	margin: 20px 0px 0px auto;
}

.is-mobile .textimage-button {
	margin: 20px auto 0px auto;
	display: block;
}

.textimage-button.is-more {
	font-size: 16px;
	color: #1a234c!important;
	background-color: transparent;
	text-align: left;
	padding-left: 40px;
}

.is-mobile .textimage-button.is-more {
	font-size: 14px;
	text-align: center;
	padding-left: 0px;
}

.textimage-button.is-more >>> .icon {
	position: absolute;
	right: -20px;
	top: 4px;
	z-index: 2;
}

.textimage-button.is-more >>> .icon i {
	color: #1D79D1!important;
	font-size: 24px;
	font-style: normal;
}

.textimage.is-left .textimage-button.is-more {
	text-align: right;
	padding-left: 0px;
	padding-right: 40px;
}

.is-mobile .textimage.is-left .textimage-button.is-more {
	padding-right: 0px;
	text-align: center;
}

.textimage-button.is-more:before {
	content: '';
	display: block;
	width: 58px;
	box-sizing: border-box;
	height: 58px;
	border-radius: 50%;
	position: absolute;
	z-index: 1;
	left: -38px;
	top: -7px;
	border: 4px solid #1D79D1;
}

.is-mobile .textimage-button.is-more:before {
	width: 40px;
	height: 40px;
	top: 2px;
}

.textimage.is-left .textimage-button.is-more:before {
	left: auto;
	right: -38px;
}

.is-mobile .textimage.is-left .textimage-button.is-more:before {
	left: auto;
	right: -28px;
}

</style>
